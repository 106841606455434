/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

@import "ag-grid-community/dist/styles/ag-grid.css";
@import "ag-grid-community/dist/styles/ag-theme-alpine.css";

// Fix for snackbar button color
body.light .mat-simple-snackbar-action, body .light .mat-simple-snackbar-action{
    color: var(--fuse-primary)
}